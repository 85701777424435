import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Swagger = makeShortcode("Swagger");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "third-party-product-api"
    }}>{`Third Party Product API`}</h1>
    <h2 {...{
      "id": "api-reference"
    }}>{`API reference`}</h2>
    <p><a parentName="p" {...{
        "href": "http://petstore.swagger.io/?url=https://api.entur.io/api-docs/third-party-products?group%3dpublic"
      }}>{`See the API reference on the Swagger Petstore`}</a></p>
    <Swagger url="https://api.entur.io/api-docs/third-party-products?group%3dpublic" baseUrl="https://api.entur.io/sales" mdxType="Swagger" />
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <h3 {...{
      "id": "background"
    }}>{`Background`}</h3>
    <p>{`Third party product is a catalog for products that does not exist in Enturs standard product database.
Therefore, third party products must be provided by the client when creating an order. This means third party products are always connected to a specific order line within an order.
Third party products enable selling products from another marketplace, using Entur's payment and order apis, which also includes that the sale is settled correctly in Entur's accounting.`}</p>
    <h3 {...{
      "id": "create"
    }}>{`Create`}</h3>
    <p>{`You create third party products through a special reserve api from the reserve-offers-api (also seen in Reserve Offer API menu)
`}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/reserve-offer#/Reserve%20Third%20Party%20Product%20API/createThirdPartyProducts"
      }}>{`See the API reference on the Swagger Petstore`}</a></p>
    <h3 {...{
      "id": "retrieve"
    }}>{`Retrieve`}</h3>
    <p>{`If you have access to the order, you have access to its third party products.
On the order, there is a salesPackageRef with an ID. If this ID equals "ENT:SalesPackage:ThirdPartyProduct", then this means there exist third party products for this order line.
From the third party product api you can read rules and display name for a given product, depending on what was defined when creating it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      